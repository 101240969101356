import RoutesService from '@/services/whatsapp/routes.service.js'
import SessionsService from '@/services/whatsapp/sessions.service'
import { Route } from '@/models/whatsapp/Route.js'

export default {
  name: 'MyLine',
  data () {
    return {
      route: new Route(),
      showModalChangeLineName: false,
      ready: false,
      activationDialog: false,
      activationCode: '',
      disabledActivateLineBtn: false,
      disabledVerifyBtn: false,
      disabledDisconnectBtn: false,
      routeQualities: {
        0: 'Alta',
        1: 'Media',
        2: 'Baja',
      },
      acceptCostMonth: false,
      phoneId: null,
      msisdn: null,
      totalNotExpired: 0,
    }
  },
  methods: {
    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },
    getLine () {
      RoutesService.getLineByMsisdn({ msisdn: this.msisdn })
      .then((route) => {
        this.route.loadData(route)
        this.resetReady()

        SessionsService.getCountNotExpiredConversations({ msisdn: this.msisdn })
        .then(countNotExpiredConversations => {
          this.totalNotExpired = countNotExpiredConversations
        })
      })
    },
    sendCodeOpenDialog () {
      this.disabledActivateLineBtn = true
      this.activationCode = ''
      RoutesService.sendActivationCode({ msisdn: this.msisdn })
      .then(() => {
        this.disabledActivateLineBtn = false
        this.activationDialog = true
      })
    },
    activateLine () {
      this.disabledVerifyBtn = true
      RoutesService.verifyActivationCode({ msisdn: this.msisdn, activationCode: this.activationCode })
      .then(() => {
        this.disabledVerifyBtn = false
        this.activationDialog = false
        this.getLine()
      })
    },
    disconnect () {
      this.disabledDisconnectBtn = true
      RoutesService.disconnect()
      .then(() => {
        this.disabledDisconnectBtn = false
        this.getLine()
      })
    },
  },
  computed: {
    routeQuality () {
      return this.routeQualities[this.route.routeQuality.id]
    },
    availableConversations () {
      const totalConversations = parseInt(this.route.routeLevel.name)
      if (isNaN(totalConversations)) {
        return ''
      } else {
        return (totalConversations - parseInt(this.totalNotExpired)) + '/'
      }
    },
  },
  created () {
    this.phoneId = this.$route.query.phone_id
    this.msisdn = this.$route.query.msisdn
    this.getLine()
  },
}
