import { IdName } from './IdName.js'

export class Route {
  id = null
  userId = null
  phoneId = null
  wabaId = null
  credentials = null
  msisdn = null
  status = null
  level = null
  quality = null
  countryIso2 = null
  wabaStatus = null
  displayName = null
  routeQuality = new IdName()
  routeWabaStatus = new IdName()
  routeStatus = new IdName()
  country = { }
  routeLevel = new IdName()
  routePartner = null

  constructor (data) {
    this.loadData(data)
  }

  // eslint-disable-next-line complexity
  loadData (data) {
    if (!data) return
    this.id = data.id
    this.userId = data.user_id
    this.phoneId = data.phone_id
    this.wabaId = data.waba_id
    this.credentials = data.credentials
    this.msisdn = data.msisdn
    this.status = data.status
    this.level = data.level
    this.quality = data.quality
    this.countryIso2 = data.country_iso2
    this.wabaStatus = data.waba_status
    this.displayName = data.display_name
    this.routeQuality.loadData(data.route_qualities)
    this.routeWabaStatus.loadData(data.route_waba_status)
    this.routeStatus.loadData(data.route_status)
    this.country = data.country
    this.routeLevel.loadData(data.route_level)
    this.routePartner = data.route_partner
  }

  canActivate () {
    return this.wabaStatus === 0 || this.wabaStatus === 8
  }
}
