export class IdName {
  id = null
  name = null
  constructor (data) {
    this.loadData(data)
  }

  // eslint-disable-next-line complexity
  loadData (data) {
    if (!data) return
    this.id = data.id
    this.name = data.name
  }
}
